<template>
    <!-- 校园安全 > 宿舍考勤 > 考勤异常通知 -->
    <campus-safety-dormitory-attendance-notice-scroll-wrapper />
</template>

<script>
import CampusSafetyDormitoryAttendanceNoticeScrollWrapper from "@/components/scrollWrapper/CampusSafetyDormitoryAttendanceNotice";
export default {
    name: "CampusSafetyDormitoryAttendanceScheduling",
    components: {
        CampusSafetyDormitoryAttendanceNoticeScrollWrapper
    }
}
</script>

<style scoped>

</style>
