<template>
    <!-- 校园安全 > 宿舍考勤 > 考勤排班 -->
    <div>
        <campus-safety-dormitory-attendance-scheduling-scroll-wrapper
            v-if="type === 1"
            @changeType="changeType"
            @setPageInitTabs="setPageInitTabs"
        ></campus-safety-dormitory-attendance-scheduling-scroll-wrapper>
        <campus-safety-dormitory-attendance-notice
            v-else
            @changeType="changeType"
            @setPageInitTabs="setPageInitTabs"
        ></campus-safety-dormitory-attendance-notice>
    </div>
</template>

<script>
import CampusSafetyDormitoryAttendanceSchedulingScrollWrapper from "@/components/scrollWrapper/CampusSafetyDormitoryAttendanceScheduling";
import CampusSafetyDormitoryAttendanceNotice from "@/components/scrollWrapper/CampusSafetyDormitoryAttendanceNotice";

export default {
    name: "CampusSafetyDormitoryAttendanceScheduling",
    components: {
        CampusSafetyDormitoryAttendanceSchedulingScrollWrapper,
        CampusSafetyDormitoryAttendanceNotice,
    },
    data() {
        return {
            type: 1,
            types:1
        };
    },
    activated () {
        this.setGlobalPageType(this.types)
    },
    methods: {
        changeType(type) {
            this.type = type;
            if (type === 1) {
                this.setGlobalPageType(type);
            } else {
                this.setGlobalPageType("detail");
            }
        },
        /**
         * @Description: 页面状态 list列表 detail二级页面(form),是否显示面包屑
         * @DoWhat:
         * @UseScenarios:
         * @Attention:
         * @Author: xwn
         * @Date: 2023-07-21 16:00:42
         */
        setGlobalPageType (type) {
            this.types = type
            this.$eventDispatch('setGlobalPageType', type)
        },
        setPageInitTabs() {
            this.setGlobalPageType('list');
            this.type = 1;
        }
    },
};
</script>

<style scoped></style>
