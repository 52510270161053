import { render, staticRenderFns } from "./CampusSafetyDormitoryAttendanceScheduling.vue?vue&type=template&id=1ecf7742&scoped=true"
import script from "./CampusSafetyDormitoryAttendanceScheduling.vue?vue&type=script&lang=js"
export * from "./CampusSafetyDormitoryAttendanceScheduling.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ecf7742",
  null
  
)

export default component.exports