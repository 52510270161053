<template>
  <!--校园安全 > 出入校管理 > 出入校记录-->
  <campus-safety-access-manage-access-record-scroll />
</template>

<script>
import CampusSafetyAccessManageAccessRecordScroll from '@/components/scrollWrapper/CampusSafetyAccessManageAccessRecord'
export default {
  name: "CampusSafetyAccessManageAccessRecord",
  components: {
    CampusSafetyAccessManageAccessRecordScroll
  }
}
</script>

<style scoped>

</style>
