<template>
  <!--校园安全 > 体温监测 > -->
  <campus-safety-temperature-monitoring-scroll />
</template>

<script>
import CampusSafetyTemperatureMonitoringScroll from '@/components/scrollWrapper/CampusSafetyTemperatureMonitoring'
export default {
  name: "CampusSafetyTemperatureMonitoring",
  components: {
      CampusSafetyTemperatureMonitoringScroll
  }
}
</script>

<style scoped>

</style>
