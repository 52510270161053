<template>
  <!--校园安全 > 出入校管理 > 出入校分析-->
  <campus-safety-access-manage-access-analysis-scroll />
</template>

<script>
import CampusSafetyAccessManageAccessAnalysisScroll from '@/components/scrollWrapper/CampusSafetyAccessManageAccessAnalysis'
export default {
  name: "CampusSafetyAccessManageAccessAnalysis",
  components: {
    CampusSafetyAccessManageAccessAnalysisScroll
  }
}
</script>

<style scoped>

</style>
