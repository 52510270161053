<template>
  <!--办公OA > 走读表单 > -->
  <CampusWalkThroughFormScroll />
</template>

<script>
import CampusWalkThroughFormScroll from '@/components/scrollWrapper/CampusWalkThroughForm'
export default {
  name: "CampusWalkThroughForm",
  components: {
    CampusWalkThroughFormScroll
  }
}
</script>

<style scoped>

</style>
