<template>
    <!-- 校园安全 > 宿舍考勤 > 考勤明细 -->
    <campus-safety-dormitory-attendance-details-scroll-wrapper />
</template>

<script>
import CampusSafetyDormitoryAttendanceDetailsScrollWrapper from "@/components/scrollWrapper/CampusSafetyDormitoryAttendanceDetails";
export default {
    name: "CampusSafetyDormitoryAttendanceDetails",
    components: {
        CampusSafetyDormitoryAttendanceDetailsScrollWrapper
    }
}
</script>

<style scoped>

</style>
