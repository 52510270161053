
<template>
    <div class="loading" v-loading="loading">
        <!-- <h1>跳转中...   http://localhost:8080/?projectMark=1#/autoJump?type=1&token=sdfsfsdfsdfsfd</h1> -->
    </div>
</template>

<script>
import { LoginModel } from '@/models/Login.js'
export default {
    name: "AutoJump",
    data () {
        return {
            loading: true
        }
    },
    created () {
        this.getEducationAutoLogin()
    },
    methods: {
        getEducationAutoLogin () {
            console.log('const search = window.location.search', window.location.search)
            console.log('=======getParameterByName',)
            let jumpParameter = this.getParameterByName("jumpParameter")
            let query = {
                jumpParameter:jumpParameter
                // "jumpParameter":"894a1f41e9bfc4503fcb3250ef6ce457e8a785ddd7101421e36b2e131478075e57e31040b884c7f2d2f757e02a8b4dd483815559bd196a7c982aacdf446f5239e6719ba42f918a934a310118b8dec93d5c36e2ef7ef8be32c8388157ab92e2fb50063e7380bdeeb98f2bf26f10c0948807f2a1eb52075fdb4f3e020aa39444075b1b47bdfbae57e9a3d8be025acff4f439225be22854e3d3d68d53424c133d4c38564617684fa33b7b7ac84a6ed804482581391912752f50b1dff431780cfaa720bb3cd82f307069ce67c468ed8935811163a05704b7b4ece6efeb53208321bbd521c388082f9a5e7df78cb6a5a9da94b0d4e3c60ed64038754e8fd00e6d90e155e13e57a24e787c26c42c7826fd0d5b1792a052fa302284d9a4dc1d0853ea646864e9cae5d67caaff024a011d6813e81a236a7c0ccddd787760419b1a5bcbfa1646d41f3a9849faf9df7371630d33735b02c652f4873131a7cea2e7132d847a1cfe3210739bd2fd415180237786f1e23d931658aacf9c278a49a747fad4c3416ce49ef3f4c6af7a8b48973e5d19a27de83bab2ef7b3b4aed6791906e29afe137d109b37728d8631bc839859dee8ed7f5b1be63d83611d1dae1b6f587094afc2a8f9d40f3a7873a5076a58c26ac56c46"
            }
            const loginModel = new LoginModel();
            loginModel.getEducationAutoLogin(query).then(res => {
                if(res.data.code == 200){
                    let data = res.data.data
                    let schoolBackUrl = window.location.origin
                    console.log(data)
                    if(data.isGroup){
                        let schoolUrl =
                            schoolBackUrl +
                            data.uri +
                            "&token="+data.auth.token + "#/";
                            console.log(window.location.origin,data.uri,'学校跳转---',schoolUrl)

                        window.location.href = schoolUrl
                    }else{
                        let schoolUrl = schoolBackUrl + data.uri + "&token=" + data.auth.token + "&schoolName=" + data.schoolName + "#/";
                        console.log('学校跳转',schoolUrl)
                        window.location.href = schoolUrl
                    }
                }else{
                    this.$message.error(res.data.msg)
                }
                this.loading = false
                // if (res.code == 200) {
                //     this.$router.push
            })
        },
        getParameterByName(name) {
            const url = window.location.href;
            name = name.replace(/[[\]]/g, '\\$&');
            const regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)');
            const results = regex.exec(url);
            if (!results) {
                return null;
            }
            if (!results[2]) {
                return '';
            }
            return decodeURIComponent(results[2].replace(/\+/g, ' '));
        }

    }
}
</script>

<style lang="scss" scoped>
.loading{
    height: 100%;
}
</style>
