<template>
    <!-- 教务综合/资产管理/仓库资产 -->
    <asset-warehouse-assets-scroll-wrapper></asset-warehouse-assets-scroll-wrapper>
</template>

<script>
import AssetWarehouseAssetsScrollWrapper from '@/components/scrollWrapper/AssetWarehouseAssets.vue';
export default {
    name: "AssetWarehouseAssets",
    components: {
        AssetWarehouseAssetsScrollWrapper
    }
}
</script>

<style scoped>

</style>