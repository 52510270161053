<!-- 智慧安全 / 出入校管理系统 / 出入校管理-->
<template>
  <KeepAliveRouterView />
</template>

<script>
export default {
  name: "CampusSafetyAccessManage"
}
</script>

<style scoped>

</style>
