<template> 
    <!-- 教务综合/资产管理 -->
    <KeepAliveRouterView />
</template>

<script>
export default {
    name: "AssetManage"
}
</script>

<style scoped>

</style>