<template>
    <!-- 教务综合/资产管理/部门资产 -->
    <assetDepartmental-assets-scroll-wrapper></assetDepartmental-assets-scroll-wrapper>
</template>

<script>
import AssetDepartmentalAssetsScrollWrapper from '@/components/scrollWrapper/AssetDepartmentalAssets.vue';
export default {
    name: "AssetDepartmentalAssets",
    components: {
        AssetDepartmentalAssetsScrollWrapper
    }
}
</script>

<style scoped>

</style>