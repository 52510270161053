<template>
    <!-- 教务综合/资产管理/仓库管理 -->
    <asset-warehouse-details></asset-warehouse-details>
</template>

<script>
import AssetWarehouseDetails from "../components/scrollWrapper/AssetWarehouseDetails.vue"

export default {
    name: "AssetWarehouseManage",
    components:{AssetWarehouseDetails}
}
</script>

<style scoped>

</style>