<template>
    <!--钉钉消息点击跳转页面，实现单点登录-->
    <div></div>
</template>

<script>
    import { getCode } from "@/libs/dingtalk";
    import { setToken } from "@/libs/auth";
    import * as dd from "dingtalk-jsapi";
    import { mapMutations, mapState } from "vuex";
    import { param2Obj } from "@/libs/utils";

    export default {
        name: "CampusOALogin",
        created() {
            this.init()
        },
        computed: {
            ...mapState({
                projectMark: state => state.projectMark,
            })
        },
        methods: {
            ...mapMutations(['setOAQueryData', 'setDealtNum']),
            init() {
                let urlParam = this.$route.query
                if (dd.env.platform !== 'notInDingTalk' && urlParam.id && urlParam.title) {
                    const projectMark = this.projectMark || ''
                    getCode(code => {
                        this._fet('/auth/sc/dingLoginHszx', {
                            code: code,
                            projectMark: projectMark,
                            mode: 'pc'
                        }).then((res) => {
                            if (res.data.code === '200') {
                                setToken(res.data.data.token);
                                this.setOAQueryData({
                                    id: urlParam.id, // 申请记录id
                                    dataTitle: urlParam.title, // 申请title
                                    backData: {
                                        name: 'DealtList',
                                    }
                                })
                                this.$router.push({
                                    name: 'CoworkingCampusOAOAApprovalApplyDetail'
                                })
                            } else {
                                this.$router.push({
                                    name: '/login'
                                });
                            }
                        })
                    }, projectMark)
                } else {
                    this.$router.push({
                        name: '/login'
                    });
                }
            }
        }
    }
</script>

<style scoped>

</style>
