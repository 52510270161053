import { render, staticRenderFns } from "./CampusSafetyAccessManage.vue?vue&type=template&id=79e0d071&scoped=true"
import script from "./CampusSafetyAccessManage.vue?vue&type=script&lang=js"
export * from "./CampusSafetyAccessManage.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "79e0d071",
  null
  
)

export default component.exports