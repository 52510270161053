<template>
  <!--校园安全 > 出入校管理 > 出入校设置-->
  <campus-safety-access-manage-access-setting-scroll />
</template>

<script>
import CampusSafetyAccessManageAccessSettingScroll from '@/components/scrollWrapper/CampusSafetyAccessManageAccessSetting'
export default {
  name: "CampusSafetyAccessManageAccessSetting",
  components: {
    CampusSafetyAccessManageAccessSettingScroll
  }
}
</script>

<style scoped>

</style>
