<template>
    <div class="building-wrap">
        <img src="@/assets/images/building.png" alt="">
    </div>
</template>

<script>

export default {
    name: 'Building',
    methods: {
    },
}
</script>

<style lang="scss" scoped>
.building-wrap {
    position: relative;
    width: 100%;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
}
img {
    position: absolute;
    left: 50%;
    transform: translate(-50%);
    width: 100%;
    min-width: 1000px;
}
</style>
